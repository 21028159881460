

















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
// import appService from "@/services/appService";
import makeSelectOptions from "@common/util/makeSelectOptions";
import { types } from "@/constants/paymentMethods";
import BlurredTenant from '@/components/BlurredTenant.vue';

export default Vue.extend({
  components: { BlurredTenant },
  data() {
    const tenant = userManager.getUserInfo().tenant;
    return {
      showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
      checkTenant: !tenant?.crossPayment,
      checkRole: userManager.checkRole([ "cpoAdmin", "cpoMonitor"], false, true),
      checkSystemAdmin: userManager.checkRole(["systemAdmin", "admin"]),
      tab : tenant?.crossPayment ? 1 : 0,
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false,
        },
        content: {
          name: " ",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Organization",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("tenants", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              userId: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "User",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              name: {
                attrs: {
                  label: "Payment Method",
                },
                rules: {
                  match: {},
                },
              },
              type: {
                type: "selectMultiple",
                attrs: {
                  label: "Type",
                  items: makeSelectOptions(types),
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          displayFields: {
            name: {
              text: "Payment Method",
              sortable: true,
            },
            type: {
              text: "Type",
              sortable: true,
              options: {
                transform: (value) => types[value],
                label: true,
              },
            },
            description: {
              text: "Description",
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Updated Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                label: true,
                sortBy: "tenantId",
              },
            },
            action: {
              text: "Actions",
            },
          },
          topActionButtons: {
            insert: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              ext: {
                condition() {
                  return true;
                  // return appService.activeTenantValid();
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {},
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("paymentMethods", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("paymentMethods", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "paymentMethods",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("paymentMethods", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Payment Method",
                  required: true,
                },
              },
              type: {
                type: "select",
                attrs: {
                  label: "Type",
                  items: makeSelectOptions(types),
                  required: true
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Description",
                },
              },
              appUrl: {
                attrs: {
                  label: "App URL",
                },
              },
              appKey: {
                attrs: {
                  label: "App Key",
                },
              },
              appSecret: {
                attrs: {
                  label: "App Secret",
                },
              },
              partner: {
                attrs: {
                  label: "Partner",
                },
              },
              publicKey: {
                attrs: {
                  label: "Public Key",
                },
              },
            },
            insertForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: ["name", "type", "description", "appUrl", "appKey", "appSecret", "partner", "publicKey"],
              },
            },
            editForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: ["name", "type", "description", "appUrl", "appKey", "appSecret", "partner", "publicKey"],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      },
      myPaymentOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false,
        },
        content: {
          name: " ",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              bankCardNumber: {
                attrs: {
                  label: "Bank Number",
                },
                rules: {
                  match: {},
                },
              },
              cardHolderName: {
                attrs: {
                  label: "Name",
                },
                rules: {
                  match: {},
                },
              },
              bankName: {
                attrs: {
                  label: "Bank Name",
                },
                rules: {
                  match: {},
                },
              },
            },
          },
          displayFields: {
            bankCardNumber: {
              text: "Bank Number",
              sortable: true,
            },
            cardHolderName: {
              text: "Name",
              sortable: true,
            },
            bankName: {
              text: "Bank Name",
            },
            bankBranch: {
              text: "Bank Branch",
            },
            default: {
              text: "Default",
              sortable: true,
              options: {
                transform: (value) => (value ? "Yes" : "No"),
                label: true,
              },
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Updated Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                label: true,
                sortBy: "tenantId",
              },
            },
            action: {
              text: "Actions",
            },
          },
          topActionButtons: {
            insert: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              ext: {
                condition() {
                  // return appService.activeTenantValid();
                  return true;
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {},
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("userPayments", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("userPayments", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "userPayments",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("userPayments", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              bankCardNumber: {
                attrs: {
                  label: "Bank Number",
                  required: true,
                },
              },
              cardHolderName: {
                attrs: {
                  label: "Holder Name",
                  required: true
                },
              },
              bankName: {
                attrs: {
                  label: "Bank Name",
                  required: true
                },
              },
              bankBranch: {
                attrs: {
                  label: "Bank Branch",
                },
              },
              default: {
                type: "boolean",
                attrs: {
                  label: "Default",
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Description",
                },
              },
            },
            insertForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: ["bankCardNumber", "cardHolderName", "bankName", "bankBranch", "default", "description"],
              },
            },
            editForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: ["bankCardNumber", "cardHolderName", "bankName", "bankBranch", "default", "description"],
              },
            },
            delete: {
              confirmDisplayField: "bankCardNumber",
            },
          },
        },
      },
      tableOptionsOther: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false,
        },
        content: {
          name: " ",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Organization",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("tenants", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              userId: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "User",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              name: {
                attrs: {
                  label: "Payment Method",
                },
                rules: {
                  match: {},
                },
              },
              type: {
                type: "selectMultiple",
                attrs: {
                  label: "Type",
                  items: makeSelectOptions(types),
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          displayFields: {
            name: {
              text: "Payment Method",
              sortable: true,
            },
            type: {
              text: "Type",
              sortable: true,
              options: {
                transform: (value) => types[value],
                label: true,
              },
            },
            description: {
              text: "Description",
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Updated Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                label: true,
                sortBy: "tenantId",
              },
            },
            action: {
              text: "Actions",
            },
          },
          topActionButtons: {
            insert: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              ext: {
                condition() {
                  return true;
                  // return appService.activeTenantValid();
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {},
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("paymentMethods", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("paymentMethods", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "paymentMethods",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("paymentMethods", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Payment Method",
                  required: true,
                },
              },
              type: {
                type: "select",
                attrs: {
                  label: "Type",
                  items: makeSelectOptions(types),
                  required: true
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Description",
                },
              },
              appUrl: {
                attrs: {
                  label: "App URL",
                },
              },
              appKey: {
                attrs: {
                  label: "App Key",
                },
              },
              appSecret: {
                attrs: {
                  label: "App Secret",
                },
              },
              partner: {
                attrs: {
                  label: "Partner",
                },
              },
              publicKey: {
                attrs: {
                  label: "Public Key",
                },
              },
            },
            insertForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: ["name", "type", "description", "appUrl", "appKey", "appSecret", "partner", "publicKey"],
              },
            },
            editForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: ["name", "type", "description", "appUrl", "appKey", "appSecret", "partner", "publicKey"],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
  methods: {
    changeTab(tab) {
      console.log('changeTab', tab);

      this.tab = tab;
    },
  },
});
